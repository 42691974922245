import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { Context } from '@nuxt/types';
import {
  GFTheme,
  GFThemeMap,
  HotelDetail,
  HotelBrandDetail,
  SiteSettings,
} from '~/schemes';

import { State as RouterState } from '~/plugins/router';

/** indexファイルにおけるstate */
export interface State {
  themes: GFThemeMap;
  route: RouterState;
  accept: string;
  host: string;
  /**
   * htmlがレンダリングされた時間
   * @Comment ISO基準の時間をstring型で保持
   */
  renderingDateTime: string;
}

export const state = (): State =>
  ({
    themes: {},
    accept: '',
    host: '',
    // route: {} as RouterState,
    renderingDateTime: '',
  } as State);

export const getters: GetterTree<State, State> = {
  themes(state): GFThemeMap {
    return state.themes;
  },
  defaultTheme(state, getters): GFTheme {
    return getters.themes.default;
  },
  matchedTheme(state, getters): GFTheme | undefined {
    let theme: GFTheme | undefined;
    const { themes } = getters;
    const hotel = getters['hotel/current'] as HotelDetail | null;
    const brand = getters['hotel/currentBrand'] as HotelBrandDetail | null;
    if (hotel && hotel.theme) {
      theme = themes[hotel.theme] || theme;
    } else if (brand && brand.theme) {
      theme = themes[brand.theme] || theme;
    }
    return theme;
  },
  theme(state, getters): GFTheme {
    const defaultTheme: GFTheme = getters.defaultTheme;
    const matchedTheme: GFTheme | undefined = getters.matchedTheme;
    const sections = defaultTheme.sections.slice();
    const theme: GFTheme = {
      ...(matchedTheme || defaultTheme),
      sections,
    };
    const matchedSections = matchedTheme && matchedTheme.sections;
    if (matchedSections) {
      matchedSections.forEach((section) => {
        const _defaultSection = sections.find((s) => s.key === section.key);
        if (_defaultSection) {
          const index = sections.indexOf(_defaultSection);
          const {
            hiddenNavigation = _defaultSection.hiddenNavigation,
          } = section;
          sections.splice(index, 1, {
            key: section.key,
            label: section.label || _defaultSection.label,
            longLabel: section.longLabel || _defaultSection.longLabel,
            order:
              section.order == null ? _defaultSection.order : section.order,
            hiddenNavigation,
          });
        }
      });
      sections.sort((a, b) => {
        const ao = a.order as number;
        const bo = b.order as number;
        if (ao < bo) return -1;
        if (ao > bo) return 1;
        return 0;
      });
    }
    return theme;
  },
};

/** vuexのストアにおいて中身を書き換えたりの処理を行う */
export const actions: ActionTree<State, State> = {
  /** 必ずページが初期化するときに呼ばれるメソッド */
  /**
   * actionの中で情報を書き換えるのはお作法的にNG
   * 書き換え場所を一箇所に制限したい
   */
  async nuxtServerInit({ dispatch, commit }, ctx: Context) {
    /** プラグインで初期化されていたらそのデータを使う */
    commit(
      'SET_RENDERING_DATE_TIME',
      this.$env.renderingDateTime || new Date().toISOString(),
    );
    if (ctx.req.url && ctx.req.url.match(/^\/gf-hooks\//)) {
      return;
    }

    await dispatch('language/serverInit', ctx);
    await Promise.all([
      dispatch('commons/serverInit', ctx),
      dispatch('loadThemes', ctx),
    ]);
  },
  async nuxtClientInit({ dispatch }, ctx: Context) {
    await dispatch('language/clientInit', ctx);

    // Nuxtのルートコンポーネントのmountedに介入する
    // このフックの中で実行しないと、初期化がすぐ終わると、ハイドレーションエラーになる
    const { mounted } = this.app;

    this.app.mounted = async function () {
      mounted && mounted.call(this);
      await Promise.all([
        dispatch('account/clientInit', ctx),
        dispatch('commons/clientInit', ctx),
      ]);
    };
  },
  async loadThemes({ commit }, ctx: Context) {
    const themes = await ctx.$dataBucket.get<GFThemeMap>('/themes');
    commit('SET_THEMES', themes);
  },
};

/** データの書き換え処理を行う */
export const mutations: MutationTree<State> = {
  SET_THEMES(state, themes: GFThemeMap) {
    const defaultTheme = themes.default;
    if (defaultTheme) {
      defaultTheme.sections = defaultTheme.sections.map((sec, i) => {
        return {
          ...sec,
          order: sec.order == null ? i * 10 : sec.order,
        };
      });
    }
    state.themes = themes;
  },
  SET_ACCEPT(state, accept?: string) {
    state.accept = accept || '';
  },
  SET_HOST(state, host?: string) {
    state.host = host || '';
  },
  /**  */
  SET_RENDERING_DATE_TIME(state, renderingDateTime: string) {
    state.renderingDateTime = renderingDateTime;
  },
};
